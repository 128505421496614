@media (max-width: 640px) {
  .main-header .nav-link{
    display: none;
    position: absolute;
    top: 115px;
    z-index: 100;
  }
  .main-header .mobile-nav-link{
    display: block;
    position: absolute;
    top: 20px;
  }
  .footer-image img{
    max-width: 170px;
  }
  .slick-list, .slick-slider{
    /* max-height: 180px; */
  }
  .slideshow-detail {
    position: absolute;
    bottom: 30px;
    left: 20px;
    width: calc(100% - 40px);
  }
  .main-header .logo-wrapper img {
    width: 130px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }  
  .main-header .logo-wrapper {
    width: 180px;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
  }
  .main-header .nav-link ul li {
    border: 1px solid;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 0px;
    width: 100vw;
    display: block;
  }
  ul.main-menu {
    display: block;
    list-style: none;
    padding: 0px;
    margin: 0px;
    width: 300px;
  }
  ul.main-menu li .sub-menu{
      position: relative;
      display: block;
      padding: 0px;
      margin: 0px;
  }
  ul.main-menu li a {
    padding: 10px;
    display: block;
    outline: 0.5px solid #000;
  }
  ul.main-menu li .sub-menu li a{
    padding-left: 30px;
  }
  ul.sub-menu li{
    width: 100% !important;
    outline: 1px solid !important;
  }
  .archive-container-item {
    width: 100%;
    height: 100vw;
    box-shadow: inset 0 1px 0 #000;
  }
  .grid-container{
    grid-template-columns: repeat(2, 50%);
  }
  .grid-container-item, .artist-container-item, .program-container-item{
    height: 50vw;
    width: 50vw;
  }
  .grid-container-item-section .row50.cover-image-full{
    display: none;
  }
  .grid-container-item-section.grid-expanded {
    display: grid;
    grid-template-columns: repeat(auto-fit, 100%);
  }
  .show-detail-page .grid-container-item-section.show {
    display: block;
    height: auto;
  }
  .show-detail-page .grid-container-item-section.show .row50.cover-image-full {
    display: block;
  }
  .show-detail-page .grid-container-item-section.show .row50{
    overflow-y: visible;
    padding-bottom: 20px;
  }
  .artist-container{
    display: block;
  }
  .artist-container-profile{
    width: 100vw;
    height: 100vw;
  }
  .artist-container-detail{
    width: 100vw;
    height: auto;
  }
  .artist-container-information{
    padding: 20px;
  }
  .about-box-right, .about-box-left{
    padding: 10px;
    width: 100%;
  }
  .about-text-head{
    margin-bottom: 10px !important;
  }
}
