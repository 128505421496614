* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
body, html{
  padding: 0px;
  margin: 0px;
  overflow-x: hidden !important;
  font-family: 'Roboto',Arial, sans-serif;
  color: #000000;
}
a, a:visited, a:focus{
  text-decoration: none;
  color: #000000;
  outline: none;
  border: none;
}
a:hover{
  text-decoration: underline;
}
.react-datepicker-popper{
  z-index: 100;
}
.main-header{
  display: block;
  width: 100%;
  position: relative;
}
.main-header .logo-wrapper{
  width: 160px;
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}
.main-header .logo-wrapper img {
  width: 160px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.main-header .logo-wrapper a{
  font-size: 20px;
}
.main-header .nav-link{
  text-align: center;
  background-color: #fff;
}
.main-header .mobile-nav-link{
  display: none;
}
.main-header .nav-link ul {
  margin: 0px;
  padding: 0px;
  border-top: 1px solid;
  border-bottom: 1px solid;
}
.main-header .nav-link ul li a {
  padding: 8px;
  display: block;
}
.main-header .nav-link ul li a:hover{
  text-decoration: none;
  background-color: #b5ff00;
}
.main-header li, .main-footer li{
  position: relative;
  display: inline-block;
  min-width: 100px;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
}
.main-header .nav-link ul li{
  border: 1px solid;
  border-right: 0px;
  border-top: 0px;
  border-bottom: 0px;
  width: 25%;
  /* width: calc(100vw / 7); */
}
.main-header .nav-link ul li:last-child{
  border-right: 1px solid;
}
.login-page {
  min-height: calc(100vh - 152px);
}
.main-slider .slick-dots {
  bottom: 10px;
}
.slick-prev{
  left: 0px !important;
  position: absolute !important;
  width: 95px !important;
  height: 100% !important;
}
.slick-next {
  right: 0px !important;
  position: absolute !important;
  width: 95px !important;
  height: 100% !important;
}
.slick-prev, .slick-next{
  z-index: 100;
}
.slick-prev:before {
  content: "<";
  font-family: sans-serif;
  text-shadow: 1px 1px 5px #000000ab;
}
.slick-next:before {
  content: ">";
  font-family: sans-serif;
  text-shadow: 1px 1px 5px #000000ab;
}
.main-footer{
  padding: 10px;
  font-size: 12px;
  background-color: #212121;
  color: #fff;
}
.main-footer a {
  color: #fff;
  display: block;
}
.main-footer a.active{
  text-decoration: underline;
}
.alert-text{
  margin-top: 10px;
}
.main-section-container{
  display: block;
  position: relative;
  white-space: nowrap;
  width: 100% !important;
  padding: 0px !important;
  margin: 0px;
  min-height: calc(100vh - 88px);
  display: table !important;
  table-layout: fixed;
}
.section-left-panel{
  width: 13%;
  max-width: 200px;
  display: table-cell;
  vertical-align: top;
  height: 100%;
  background-color: #dedede;
}
.section-left-panel .side-menu ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.side-menu ul li a {
  padding: 10px;
  display: block;
  background: #dedede;
  border-bottom: 1px solid #c1c1c1;
}
.side-menu ul li:last-child a{
  border-bottom: none;
}
.section-right-panel{
  width: 87%;
  min-width: calc(100% - 200px);
  display: table-cell;
  height: 100%;
  vertical-align: top;
}
.panel-section-container {
  padding-top: 20px;
  padding-bottom: 5px;
  max-height: 700px;
  overflow-y: scroll;
}
.library-grid-item-container{
  white-space: normal;
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, calc(20% - 4px));
  grid-auto-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  width: 100%;
}
.library-grid-item {
  position: relative;
  width: 100%;
  flex: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  vertical-align: middle;
  min-height: 250px;
  border: 1px solid #8b8989;
  background-color: #c5c5c5;
  cursor: pointer;
  text-align: center;
}
.library-grid-item-info {
  position: absolute;
  bottom: 0px;
  background-color: #b5ff00;
  width: 100%;
  height: 0px;
  -webkit-transition: height 0.2s ease-out;
  -moz-transition: height 0.2s ease-out;
  -o-transition: height 0.2s ease-out;
  transition: height 0.2s ease-out;
  overflow: hidden;
  text-align: center;
}
.library-grid-item-info button{
  margin: 7px;
}
.library-grid-item:hover .library-grid-item-info{
  height: 50px;
}
.library-grid-item audio{
  width: 100%;
  display: block;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator{
  display: none;
}
.artist-country-flag {
  vertical-align: middle;
  margin-left: 7px;
}
.breakspace{
  white-space: normal;
}
.archive-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: space-around;
  align-items: center;
  justify-content: flex-start;
}
.section-container-header {
  display: block;
  width: 100%;
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid #000;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 24px;
}
.archive-container-item{
  width: 25%;
  height: calc(100vw / 4);
  background-color: #efefef;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 1px 1px 0px black inset;
}
.archive-loadmore-container {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  width: 100%;
  display: block;
  text-align: center;
}
.archive-loadmore-container button {
  color: #000;
}
.archive-loadmore-container button:hover {
  background-color: rgb(0 0 0 / 18%) !important;
}

.archive-loadmore-btn {
  padding: 10px;
  display: block;
  cursor: pointer;
}
.archive-loadmore-btn:hover{
  text-decoration: none;
  background-color: #b5ff00;
}
.schedule-container {
  background-color: #000;
  color: #fff;
}
.schedule-container-item {
  display: block;
  padding: 10px;
}
.schedule-show-item:last-child{
  border-bottom: none;
}
.schedule-container-item:last-child{
  border-bottom: none;
}
.footer-image{
  position: relative;
}
.footer-image img{
  max-width: 280px;
  width: 90%;
  display: block;
}

.grid-container{
  display: grid !important;
  grid-auto-flow: dense;
  grid-gap: 1px;
  grid-template-columns: repeat(4, 25fr);
  justify-content: center;
  animation: all 1s ease;
  animation: stretch 1.5s ease-out;  
}
.grid-container-item, .artist-container-item, .program-container-item, .press-container-item, .genre-container-item{
  aspect-ratio: 1;
  aspect-ratio: 1 / 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  outline: 1px solid #000 !important;
}
.grid-expanded {
  grid-column: 1 / -1;
}
.is-hidden{
  display: none;
  height: 0px;
}
.grid-container-item-section{
  animation: all 1s ease;
  animation: stretch 1.5s ease-out;
}
.grid-container-item-section.grid-expanded{
  display: grid;
  height: auto;
  grid-template-columns: repeat(auto-fit, 50%);
}
.grid-container-item-section .row50{
  aspect-ratio: 1;
  position: relative;
  outline: 0.1px solid #000;
  overflow-y: scroll;
}
.grid-container-item-section .row50.cover-image-full{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  overflow: hidden;
}
.artist-container-item, .program-container-item, .genre-container-item{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 0px;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: column !important;
}
a.program-container-item, a.artist-container-item{
  position: relative;
  filter: grayscale(1);
}
a.program-container-item p{
  font-size: 14px;
}
a.program-container-item:hover{
  text-decoration: none;
  font-weight: 600;
}
a.artist-container-item:hover{
  text-decoration: none;
  font-weight: 600;
  filter: grayscale(0);
}
a.artist-container-item .artist-container-item-info{
  display: none;
}
a.artist-container-item:hover .artist-container-item-info{
  display: block;
  max-width: 90%;
}

a.program-container-item p, a.program-container-item div, a.artist-container-item div, a.artist-container-item p {
  z-index: 50;
}
a.program-container-item:hover::before, a.artist-container-item:hover::before{
  z-index: 1;
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  text-decoration: none;
  background-color: #00000030;
}

.genre-container-item{
  filter: grayscale(1);
}
.genre-container-item:hover{
  text-decoration: none;
  filter: grayscale(0);
}
a.genre-container-item p {
  font-weight: 500;
  font-size: 18px !important;
}
.genre-container-item-text {
  /* display: none; */
  background-color: #000000;
  color: #fff;
  padding: 5px;
  max-width: 80%;
}
.genre-container-item:hover .genre-container-item-text{
  display: block;
  text-decoration: none;
}

.grid-container-press-section{
  background-color: #ffffff;
}
.grid-container-press-section.grid-expanded{
  display: grid;
  height: auto;
  grid-template-columns: repeat(auto-fit, 100%);
  outline: 1px solid !important;
}
.grid-container-press-section .row100{
  aspect-ratio: 16 / 9;
  position: relative;
}
.press-container-item{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 0px;
  flex-wrap: wrap;
  align-content: center;
}

div#iframe-container-mixcloud {
  display: flex;
  align-items: center;
}
.btn-close-player {
  padding: 15px;
  cursor: pointer;
}
.livestream-indicator{
  width: 15px;
  height: 15px;
  padding: 0px;
  margin-right: 10px;
  background-color: #f00;
  border-radius: 30px;
  animation: fadeinout 1s linear infinite;
  -webkit-animation: fadeinout 1s linear infinite;
}
@-webkit-keyframes fadeinout {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes fadeinout {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
a.MuiChip-root:hover {
  background-color: #000;
  color: #fff;
  text-decoration: none;
}
a.MuiChip-root {
  cursor: pointer;
}
.artist-avatar{
  background-size: 80%;
  background-position: center;
  display: inline-block;
  border-radius: 50%;
  background-repeat: no-repeat;
  border: 1px solid #000;
}
.main-menu{
  position: relative;
  display: flex;
}
.sub-menu{
  position: absolute;
  display: none;
  width: 100%;
}
ul.sub-menu li {
  display: block;
  z-index: 100;
  position: relative;
  background-color: #fff;
  outline: 1px solid;
  border: none !important;
  width: 100% !important;
}
.main-header .nav-link ul li:hover ul.sub-menu{
  display: block !important;
}
a.items-opening{
  background-color: #231f20;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #ffffff;
  text-decoration: none;
}
a.items-opening:hover{
  font-weight: 900;
}

.artist-container {
  align-content: center;
  align-items: center;
  display: grid;
  height: auto;
  box-sizing: border-box;
  grid-auto-flow: dense;
  grid-gap: 1px;
  grid-template-columns: repeat(2, 50vw);
  justify-content: center;
  border-bottom: 1px solid #000;
}
.artist-container-profile {
  width: 50vw;
  height: 50vw;
  box-sizing: border-box;
  outline: 0.5px solid #000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  font-size: 10em;
  color: #cacaca;
  font-weight: 800;
}
.artist-container-detail {
  width: 50vw;
  height: 50vw;
  box-sizing: border-box;
  outline: 0.5px solid #000;
}
.artist-container-information{
  padding: 30px;
}
.artist-container-show {
  box-sizing: border-box;
  border-bottom: 1px solid;
}
.artist-profile-link {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  margin-top: 10px;
  width: fit-content;
}
.section-container-loading{
  display: flex;
  min-height: 450px;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
}
.about-container{
  width: 80%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.about-box-left{
  padding: 20px;
  width: 50%;
}
.about-box-right{
  padding: 20px;
  width: 50%;
}
.about-text-head{
  line-height: 40px !important;
  font-size: 40px !important;
  font-weight: 800 !important;
  margin-bottom: 20px !important;
}
.about-text-subhead{
  font-size: 20px !important;
  font-weight: 500 !important;
  text-align: initial !important;
  letter-spacing: -0.5px !important;
  margin-bottom: 20px !important;
}
.about-text-p{
  text-align: justify;
  display: block;
  margin-bottom: 10px !important;
  margin-top: 0px !important;
}
.slider-image-item{
  width: 100%;
  height: auto;
}
.slider-image-item img{
  width: 100%;
  height: auto;
  display: block;
}
.slideshow-detail {
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: calc(50% - 20px);
}
.slideshow-detail-title{
  background-color: #000000;
  color: #ffffff;
  font-size: 23px;
  font-weight: 800;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  display: block;
  width: fit-content;
}
.slideshow-detail-description{
  background-color: #000000;
  color: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  display: block;
  width: fit-content;
}
.slideshow-detail-link{
  background-color: #000000;
  color: #ffffff;
  width: fit-content;
}
.slideshow-detail-link a{
  color: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  display: block;
  width: fit-content;
  text-decoration: none;
}
.slideshow-detail-link a:hover{
  background-color: #868686;  
}
